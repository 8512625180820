import { formatDate } from '@angular/common';
import { InjectionToken, LOCALE_ID, inject } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  Locale,
  eachDayOfInterval,
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
} from 'date-fns';

export interface Weekday {
  id: number;
  long: string;
  short: string;
  narrow: string;
}

export function getWeekdays(): Weekday[] {
  const locale = inject<Locale>(MAT_DATE_LOCALE);

  const now = new Date();
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  return eachDayOfInterval({ start, end }).map((day) => ({
    id: day.getDay(),
    narrow: format(day, 'EEEEE', { locale }),
    short: format(day, 'EEE', { locale }),
    long: format(day, 'EEEE', { locale }),
  }));
}

export const WEEKDAYS = new InjectionToken<Weekday[]>('List of all weekdays', {
  providedIn: 'root',
  factory: getWeekdays,
});

export interface Hour {
  id: number;
  '24-hours': string;
  '12-hours': string;
}

export function getHours(): Hour[] {
  const locale = inject(LOCALE_ID);

  return Array.from({ length: 24 }, (_, hour) => {
    const date = new Date(Date.UTC(0));
    date.setHours(0 + hour, 0, 0, 0);
    return {
      id: hour,
      '24-hours': formatDate(date, 'HH:mm', locale),
      '12-hours': formatDate(date, 'hh:mm a', locale),
    };
  });
}

export const HOURS = new InjectionToken<Hour[]>(
  'List of the 24 hours of a day',
  {
    providedIn: 'root',
    factory: getHours,
  },
);

/**
 * Converts a date string to ISO 8601 format.
 *
 * @param inputDate The date string to convert, or undefined or null.
 * @returns The date in ISO 8601 format, or undefined or null if the input is undefined or null.
 */
export function convertDateToISO8601(inputDate: string): string;
export function convertDateToISO8601(inputDate: undefined): undefined;
export function convertDateToISO8601(
  inputDate: string | undefined,
): string | undefined;
export function convertDateToISO8601(inputDate: null): null;
export function convertDateToISO8601(inputDate: string | null): string | null;
export function convertDateToISO8601(
  inputDate: string | null | undefined,
): string | null | undefined;
export function convertDateToISO8601(
  inputDate: string | null | undefined,
): string | null | undefined {
  if (!inputDate) {
    return inputDate;
  }
  try {
    return parseISO(inputDate).toISOString();
  } catch (error) {
    console.error(`Cannot parse "${inputDate}" in ISO 8601 format : ${error}`);
    return inputDate;
  }
}
